<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import { isRegistered } from '@@/bits/user_model'
import { defineAsyncComponent } from '@@/bits/vue'
import { openUrlInBrowserFromZoom } from '@@/bits/zoom'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzIconButton, {
  OzIconButtonColorScheme,
  OzIconButtonSizePreset,
} from '@@/library/v4/components/OzIconButton.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import type { PopoverAnchor } from '@@/library/v4/components/OzPopoverModal.vue'
import { getAnchorFromElement } from '@@/library/v4/components/OzPopoverModal.vue'
import SearchIconWithIndicator from '@@/library/v4/components/SearchIconWithIndicator.vue'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useSlideshowStore } from '@@/pinia/slideshow'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAccountsStore } from '@@/pinia/surface_accounts_store'
import { useSurfaceActivityPanelStore } from '@@/pinia/surface_activity_panel'
import { useSurfaceAIChatStore } from '@@/pinia/surface_ai_chat_store'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfaceFreezePadletPanel } from '@@/pinia/surface_freeze_padlet_panel'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostSearchStore } from '@@/pinia/surface_post_search'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceRemakePanelStore } from '@@/pinia/surface_remake_panel'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { PostAttributes } from '@@/types'
import AppBarAccountAvatar from '@@/vuecomponents/AppBarAccountAvatar.vue'
import MagicChatIconButtonVue from '@@/vuecomponents/MagicChatIconButton.vue'
import type { Button } from '@@/vuecomponents/SurfaceAddPostMenu.vue'
import SurfaceAddPostMenu from '@@/vuecomponents/SurfaceAddPostMenu.vue'
import SurfaceGuestIdLabel from '@@/vuecomponents/SurfaceGuestIdLabel.vue'
import SurfaceOnboardingDemoPadletPulsatingWrapper from '@@/vuecomponents/SurfaceOnboardingDemoPadletPulsatingWrapper.vue'
import { useSurfaceButtonSkinBasedTwClasses } from '@@/vuecomposables/surface_button_skin_based_tw_classes'
import useZoomCollaboration from '@@/vuecomposables/zoom_collaboration'
import type { LongPressEvent } from '@@/vuedirectives/emit_long_press'
import vEmitLongPress from '@@/vuedirectives/emit_long_press'
import { debounce } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const SurfaceActionBarMoreMenu = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceActionBarMoreMenu.vue'))

const POPOVER_WIDTH = 256
const ONBOARDING_PANEL_OFFSET = POPOVER_WIDTH + 13
const moreButton = ref<InstanceType<typeof OzIconButton>>()
const isElectronApp = device.electronApp

const surfaceStore = useSurfaceStore()
const surfaceAccountsStore = useSurfaceAccountsStore()
const surfaceGuestStore = useSurfaceGuestStore()
const surfaceDraftsStore = useSurfaceDraftsStore()
const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
const surfaceSettingsStore = useSurfaceSettingsStore()
const { isContainerSmallerThanTabletPortrait, isContainerSmallerThanTabletLandscape } =
  storeToRefs(surfaceContainerSizeStore)
const {
  user,
  isMap,
  links,
  loginAndRedirectToWallUrl,
  isDesktopLayout,
  isMobileLayout,
  skinPrimaryVariantColorAsRGB,
  isSkinColorAvailable,
  buttonDarkMode,
  xActionBarMoreMenu,
  isSectionBreakout,
  wallAttributes,
  isFrozen,
  hasSidePanelOutsideSurface,
  isLibraryWall,
  isMagicWall,
  magicWallType,
  onboardingDemoButtonStyle,
} = storeToRefs(surfaceStore)
const {
  isCurrentLibrarySchool,
  isCurrentLibraryClassroom,
  isLibraryOwner,
  isLibraryAdmin,
  isLibraryTeacher,
  isTeacher,
  isAdmin,
  isOwner,
  isNativeAccount,
  isBackpack,
} = storeToRefs(surfaceAccountsStore)
const { showActionBarMoreMenu, hideActionBarMoreMenu, showRemakePanelOld, showPromptToLoginDialog } = surfaceStore
const surfacePermissionsStore = useSurfacePermissionsStore()
const { canIAdminister, canIPost, canIRemake, amIOwner, canIAccessContentDirectly } =
  storeToRefs(surfacePermissionsStore)

const { toggleSettingsPanel } = surfaceSettingsStore
const surfaceActivityStore = useSurfaceActivityPanelStore()
const { xActivityPanel } = storeToRefs(surfaceActivityStore)
const { showActivityPanel, closeActivityPanel } = surfaceActivityStore

const { showRemakePanel } = useSurfaceRemakePanelStore()
const { startPickingLocation } = useSurfaceMapStore()
const { startNewPost } = useSurfacePostsStore()
const { showSurfaceFreezePadletPanel } = useSurfaceFreezePadletPanel()
const { coverPageUrl } = storeToRefs(useSlideshowStore())

const { isSmallerThanTabletPortrait, isSmallerThanTabletLandscape } = storeToRefs(useWindowSizeStore())
const surfaceAIChatStore = useSurfaceAIChatStore()
const { toggleChatPanel } = surfaceAIChatStore
const { xSurfaceAIChatPanel, magicWallFirstOpen } = storeToRefs(surfaceAIChatStore)

const { isRemakeable } = storeToRefs(surfaceSettingsStore)

const { buttonSkinBasedTailwindClasses, buttonSkinBasedTailwindClassesWithoutHover } =
  useSurfaceButtonSkinBasedTwClasses()

const expandedPostStore = useExpandedPostStore()
const { xExpandedPostPanel } = storeToRefs(expandedPostStore)

const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()
const {
  isDemoPadletPanel,
  isDemoPadletPanelDesktop,
  demoPadletPanelVisibility,
  isShareStep,
  isStepNotStarted,
  isPostStep,
} = storeToRefs(surfaceDemoPadletPanelStore)

const isDemoPadletPanelDesktopAndPostStep = computed<boolean>(() => isDemoPadletPanelDesktop.value && isPostStep.value)

const isDemoPadletPanelDesktopAndShareStep = computed<boolean>(
  () => isDemoPadletPanelDesktop.value && isShareStep.value,
)

const useSkinBasedColorsForButtons = computed<boolean>(() => {
  return isSkinColorAvailable.value && !isMobileLayout.value
})

const buttonColorScheme = computed(() => {
  if (isSectionBreakout.value) {
    return OzIconButtonColorScheme.Transparent
  }

  if (useSkinBasedColorsForButtons.value) {
    return OzIconButtonColorScheme.Bare
  }

  return isMobileLayout.value ? OzIconButtonColorScheme.SecondaryClear : OzIconButtonColorScheme.Secondary
})

const activeButtonColorScheme = computed<OzIconButtonColorScheme>(() => {
  if (useSkinBasedColorsForButtons.value) {
    return OzIconButtonColorScheme.Bare
  }

  return isMobileLayout.value ? OzIconButtonColorScheme.SecondaryClearActive : OzIconButtonColorScheme.SecondaryActive
})

const activeButtonSkinBasedTailwindClasses = computed(() => {
  if (!useSkinBasedColorsForButtons.value) {
    return {}
  }

  // buttonDarkMode is either true or false here (cannot be 'auto') since we checked for isSkinColorAvailable
  return {
    // Base
    'bg-grey-1000 bg-opacity-5': buttonDarkMode.value === false,
    'bg-white-100 bg-opacity-10': buttonDarkMode.value === true,
    'text-grape-500': buttonDarkMode.value === false,
    'text-canary-500': buttonDarkMode.value === true,

    // Hovered
    'hover-hover:hover:bg-opacity-10': buttonDarkMode.value === false,
    'hover-hover:hover:bg-opacity-15': buttonDarkMode.value === true,

    // Disabled
    'aria-disabled:!bg-highway-400': buttonDarkMode.value === false,
    'aria-disabled:!bg-white-disabled': buttonDarkMode.value === true,
  }
})

const isUserRegistered = computed<boolean>(() => {
  return isRegistered(user.value)
})

const isRemakeDisabled = computed<boolean>(() => {
  if (!isUserRegistered.value) {
    // Check isRemakeable as canIRemake is always false for unregistered users
    // and we want the remake button to be clickable (for padlets with remakes enabled)
    // so the login dialog can be shown
    return !isRemakeable.value
  }
  return !canIRemake.value || !canIAccessContentDirectly.value
})

/**
 * MOBILE
 * - [ Share, Activity, +, Search, ... ]
 *
 * MOBILE ZOOM APP
 * - [ Slideshow, Activity, +, Search, Open in browser ]
 *
 *
 * DESKTOP
 * - [ Share, Remake, Activity, Slideshow, Settings, ... ]
 *
 * DESKTOP ZOOM APP
 * - [ Activity, Slideshow, Settings, Open in browser ]
 */

const { searchTerm } = storeToRefs(useSurfacePostSearchStore())
const { hasSelectedFilters } = storeToRefs(useSurfaceFilterStore())

const xSearchIndicator = computed<boolean>(() => {
  return searchTerm.value !== '' || hasSelectedFilters.value
})

function showSharePanel() {
  if (isDemoPadletPanelDesktopAndShareStep.value) {
    surfaceDemoPadletPanelStore.handleStartShareAnimation()
  } else {
    useSurfaceSharePanelStore().xSurfaceSharePanel = true
  }
}

function showSearchBar() {
  useSurfaceStore().openSearchBar()
}

async function openPadletInBrowser() {
  openUrlInBrowserFromZoom(links.value.show)
}

function openSlideshow() {
  trackEvent('Surface', 'Opened slideshow')
  if (!links.value.show) {
    return
  }

  if (device.zoomApp) {
    navigateTo(coverPageUrl.value)
    return
  }

  navigateTo(coverPageUrl.value, { target: '_blank' })
}

function showRequestLoginToRemakeDialog(): void {
  showPromptToLoginDialog({
    title: __('You must be logged in to remake this padlet'),
  })
}

function handleRemakeClick(): void {
  if (isUserRegistered.value && isAppUsing('remakePanelV2')) {
    showRemakePanel()
  } else if (isUserRegistered.value && !isAppUsing('remakePanelV2')) {
    showRemakePanelOld()
  } else if (!isUserRegistered.value) {
    showRequestLoginToRemakeDialog()
  }
}

const isLoginAndRedirectToWallUrlExpired = ref(false)

/**
 * ZOOM
 */
const { isZoomCollaborationGuest } = useZoomCollaboration()

/**
 * SHOW/HIDE MORE MENU
 */

function openMoreMenu(): void {
  if (!moreMenuAnchor.value.top || !moreMenuAnchor.value.left) {
    updateMoreMenuAnchor()
  }
  showActionBarMoreMenu()
}

function closeMoreMenu(): void {
  hideActionBarMoreMenu()
}

/**
 * MORE MENU POSITIONING
 */

const moreMenuAnchor = ref<Partial<PopoverAnchor>>({})

function updateMoreMenuAnchor(): void {
  const moreButtonEl = moreButton.value?.$el
  if (!moreButtonEl) return
  const anchor = getAnchorFromElement(moreButtonEl, {
    top: 0,
    left: isMobileLayout.value
      ? dir() === 'rtl'
        ? -15
        : hasSidePanelOutsideSurface.value
        ? 12 - 160 // numbers by experimentation
        : 12
      : dir() === 'rtl'
      ? hasSidePanelOutsideSurface.value
        ? -12 + ONBOARDING_PANEL_OFFSET
        : -12
      : hasSidePanelOutsideSurface.value
      ? -39 - ONBOARDING_PANEL_OFFSET
      : -39,
  })
  moreMenuAnchor.value = {
    ...anchor,
    position: 'side',
  }
}

const debouncedUpdateMoreMenuAnchor = debounce(() => {
  if (window.visualViewport && window.visualViewport.scale > 1) {
    // When the content is pinch-zoomed, prevented any resizing and recalculation.
    return
  }
  updateMoreMenuAnchor()
}, 500)

const addPostButtonRef = ref<InstanceType<typeof OzBaseButton>>()
const xAddPostMenu = ref(false)

function handleAddPostClick(): void {
  if (isMap.value) {
    startPickingLocation()
  } else {
    if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop && surfaceDemoPadletPanelStore.isPostStep) {
      surfaceDemoPadletPanelStore.setFavoritePlacesSectionAsMostRecentlyTouched()
      // Limit to one post draft at a time
      if (!surfaceDraftsStore.isAnyDraftActive) {
        const attributes: PostAttributes = {
          ...surfaceDemoPadletPanelStore.postData[0],
        }
        startNewPost({ attributes })
      }
    } else {
      startNewPost({})
    }
  }
}

function handleAddPostLongPress(e: LongPressEvent): void {
  e.preventDefault()
  if (!showSurfaceChat.value) return
  xAddPostMenu.value = true
}

const addPostMenuButtons: Button[] = [
  {
    text: __('Write a post'),
    ariaLabel: __('Write a post'),
    icon: 'pencil_outline',
    action: () => {
      startNewPost({})
    },
  },
  {
    text: surfaceAIChatStore.assistantName,
    ariaLabel: surfaceAIChatStore.assistantName,
    icon: 'sparkles_outline',
    action: () => {
      surfaceAIChatStore.toggleChatPanel()
    },
  },
]

const showSurfaceChat = computed(() => {
  const isSurfaceChatViewable = amIOwner.value && demoPadletPanelVisibility.value.showSurfaceAiChat

  if (!isSurfaceChatViewable) {
    return false
  }

  // We use the flip in this way so that we can turn on surface chat for individual users
  if (isAppUsing('surfaceAiChat')) {
    return true
  }

  const isEducationalLibrary = computed(
    (): boolean => isLibraryWall.value && (isCurrentLibraryClassroom.value || isCurrentLibrarySchool.value),
  )
  const isLibraryTeacherOrAbove = computed(
    (): boolean =>
      isEducationalLibrary.value && (isLibraryOwner.value || isLibraryAdmin.value || isLibraryTeacher.value),
  )
  const isBackpackTeacherOrAbove = computed(
    (): boolean => isBackpack.value && (isTeacher.value || isAdmin.value || isOwner.value),
  )

  if (
    isMagicWall.value &&
    magicWallType.value !== 'learning_assessment_polls' &&
    (isLibraryTeacherOrAbove.value || isBackpackTeacherOrAbove.value)
  ) {
    return true
  }

  const isNativeAdmin = computed((): boolean => isNativeAccount.value && isAdmin.value)
  if (isNativeAdmin.value) {
    return true
  }

  return false
})

/**
 * COMPONENT LIFECYCLE
 */

onMounted(async () => {
  if (loginAndRedirectToWallUrl.value) {
    // Refer to DEEPLINK_RESPONSE_WINDOW_DURATION_WITH_BUFFER in app/services/lti_launch_context.rb
    const TIMEOUT_MS = 15 * 60 * 1000
    setTimeout(() => (isLoginAndRedirectToWallUrlExpired.value = true), TIMEOUT_MS)
  }
  window.addEventListener('resize', debouncedUpdateMoreMenuAnchor)
  await surfaceAccountsStore.initialize()

  surfaceAIChatStore.loadMagicWallFirstOpen(showSurfaceChat.value) // Load data to show feedback button, remove wall options if first time
  if (showSurfaceChat.value && magicWallFirstOpen.value) surfaceAIChatStore.showChatPanel() // without having to press button to bring it up
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedUpdateMoreMenuAnchor)
})
</script>

<template>
  <div id="surface-action-bar">
    <div
      :class="[
        'flex items-center',
        isMobileLayout
          ? [
              'justify-between @tablet-portrait/surface-container:justify-around',
              'space-s-6 @tablet-portrait/surface-container:space-s-0',
              'pt-1 pb-2',
            ]
          : ['flex-col space-y-4 justify-start', 'box-border h-full py-4'],
        {
          'bg-light-ui-100 dark:bg-dark-ui-100': !isSkinColorAvailable,
        },
        {
          'rounded-b-3xl': hasSidePanelOutsideSurface && isSmallerThanTabletPortrait && !isDemoPadletPanel,
        },
      ]"
      :style="isSkinColorAvailable ? { backgroundColor: skinPrimaryVariantColorAsRGB } : {}"
      data-testid="surfaceActionBar"
    >
      <SurfaceGuestIdLabel
        v-if="
          surfaceGuestStore.shouldEnableAnonymousAttribution &&
          !isUserRegistered &&
          !xExpandedPostPanel &&
          !isSmallerThanTabletLandscape &&
          demoPadletPanelVisibility.showSurfaceActionBarButtons
        "
        :class="['self-end', 'me-4']"
      />
      <AppBarAccountAvatar
        v-else-if="!isElectronApp && !isMobileLayout"
        :class="[device.zoomApp && '!bg-transparent']"
        :user="user"
        :referrer-url="wallAttributes.links ? wallAttributes.links.show : undefined"
        :dark-mode="buttonDarkMode"
        :disabled="device.zoomApp"
      />

      <SurfaceOnboardingDemoPadletPulsatingWrapper
        v-if="demoPadletPanelVisibility.showModifiedSurfaceActionBarShareButton"
        :is-smaller-than-tablet-portrait="isContainerSmallerThanTabletPortrait"
        :has-square-shape="true"
      >
        <OzIconButton
          v-if="isMobileLayout && device.zoomApp && !isZoomCollaborationGuest"
          :label="__('Open slideshow')"
          icon-name="play_outline"
          :disabled="isSectionBreakout"
          :icon-size="24"
          :size-preset="OzIconButtonSizePreset.H40px"
          :dark-mode="buttonDarkMode"
          :class="['grow @tablet-portrait/surface-container:grow-0 text-light-text-100']"
          :data-pepin-id="'surface-action-bar-open-slideshow'"
          data-pepin-trigger="click"
          @click="openSlideshow"
        />
        <OzIconButton
          v-else-if="
            (demoPadletPanelVisibility.showSurfaceActionBarButtons && !device.zoomApp) ||
            (isMobileLayout && isZoomCollaborationGuest)
          "
          :label="__('Open share panel')"
          icon-name="share_arrow_outline"
          :disabled="
            isZoomCollaborationGuest || isSectionBreakout || (isDemoPadletPanelDesktopAndShareStep && !isStepNotStarted)
          "
          :icon-size="isMobileLayout ? 24 : 16"
          :size-preset="OzIconButtonSizePreset.H40px"
          :color-scheme="buttonColorScheme"
          :style="onboardingDemoButtonStyle"
          :dark-mode="buttonDarkMode"
          :class="[
            'grow @tablet-portrait/surface-container:grow-0',
            buttonSkinBasedTailwindClasses,
            demoPadletPanelVisibility.showModifiedSurfaceActionBarShareButton && 'text-light-text-100',
          ]"
          data-testid="surfaceShareButton"
          data-pepin-id="surface-action-bar-show-share-panel"
          data-pepin-trigger="click"
          @click="showSharePanel"
        />
      </SurfaceOnboardingDemoPadletPulsatingWrapper>
      <template v-else>
        <OzIconButton
          v-if="isMobileLayout && device.zoomApp && !isZoomCollaborationGuest"
          :label="__('Open slideshow')"
          icon-name="play_outline"
          :disabled="isSectionBreakout"
          :icon-size="24"
          :size-preset="OzIconButtonSizePreset.H40px"
          :dark-mode="buttonDarkMode"
          :class="['grow @tablet-portrait/surface-container:grow-0 text-light-text-100']"
          :data-pepin-id="'surface-action-bar-open-slideshow'"
          data-pepin-trigger="click"
          @click="openSlideshow"
        />
        <OzIconButton
          v-else-if="
            (demoPadletPanelVisibility.showSurfaceActionBarButtons && !device.zoomApp) ||
            (isMobileLayout && isZoomCollaborationGuest)
          "
          :label="__('Open share panel')"
          icon-name="share_arrow_outline"
          :disabled="
            isZoomCollaborationGuest ||
            isSectionBreakout ||
            isDemoPadletPanelDesktopAndPostStep ||
            (isDemoPadletPanelDesktopAndShareStep && !isStepNotStarted)
          "
          :icon-size="isMobileLayout ? 24 : 16"
          :size-preset="OzIconButtonSizePreset.H40px"
          :color-scheme="buttonColorScheme"
          :dark-mode="buttonDarkMode"
          :class="[
            'grow @tablet-portrait/surface-container:grow-0',
            buttonSkinBasedTailwindClasses,
            demoPadletPanelVisibility.showModifiedSurfaceActionBarShareButton && 'text-light-text-100',
          ]"
          data-testid="surfaceShareButton"
          data-pepin-id="surface-action-bar-show-share-panel"
          data-pepin-trigger="click"
          @click="showSharePanel"
        />
      </template>

      <OzIconButton
        v-if="isDesktopLayout && !device.zoomApp && demoPadletPanelVisibility.showSurfaceActionBarButtons"
        :label="isRemakeDisabled ? __('Remakes disabled') : __('Remake this padlet')"
        :disabled="
          isRemakeDisabled ||
          isSectionBreakout ||
          isDemoPadletPanelDesktopAndPostStep ||
          isDemoPadletPanelDesktopAndShareStep
        "
        icon-name="arrow_2_rectangular_clockwise_thin"
        :icon-size="isMobileLayout ? 24 : 16"
        :size-preset="OzIconButtonSizePreset.H40px"
        :color-scheme="buttonColorScheme"
        :dark-mode="buttonDarkMode"
        :class="['grow @tablet-portrait/surface-container:grow-0', buttonSkinBasedTailwindClasses]"
        data-testid="surfaceRemakePanelButton"
        :data-pepin-id="isUserRegistered && 'surface-action-bar-show-remake-panel'"
        data-pepin-trigger="click"
        @click="handleRemakeClick"
      />
      <OzIconButton
        v-if="isMobileLayout"
        :label="xActivityPanel ? __('Close activity panel') : __('Open activity panel')"
        :icon-name="xActivityPanel ? 'bell_filled' : 'bell'"
        :icon-size="24"
        :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
        :size-preset="OzIconButtonSizePreset.H40px"
        :color-scheme="xActivityPanel ? activeButtonColorScheme : buttonColorScheme"
        :dark-mode="buttonDarkMode"
        :class="[
          'grow @tablet-portrait/surface-container:grow-0',
          xActivityPanel ? activeButtonSkinBasedTailwindClasses : buttonSkinBasedTailwindClasses,
        ]"
        :data-pepin-id="
          xActivityPanel ? 'surface-action-bar-close-activity-panel' : 'surface-action-bar-open-activity-panel'
        "
        data-testid="activityPanelButton"
        data-pepin-trigger="click"
        @click="xActivityPanel ? closeActivityPanel() : showActivityPanel()"
      />
      <SurfaceOnboardingDemoPadletPulsatingWrapper
        v-if="isMobileLayout && demoPadletPanelVisibility.showModifiedSurfaceAddPostButton"
        :is-smaller-than-tablet-portrait="isContainerSmallerThanTabletPortrait"
      >
        <OzBaseButton
          v-if="canIPost && isMobileLayout && !isFrozen"
          ref="addPostButtonRef"
          v-emit-long-press
          :class="[
            'grow @tablet-portrait/surface-container:grow-0',
            'flex items-center justify-center',
            // The icon size is 32x32 so we need to
            // add 4px padding to both sides to make
            // it as wide as the other 40x40 buttons.
            'px-1',
            'bg-transparent',
          ]"
          :aria-label="__('Add post')"
          :title="__('Add post')"
          data-testid="surfaceAddPostButton"
          data-pw="surfaceAddPostButton"
          :data-pepin-id="isMap ? 'surface-action-bar-open-location-picker' : 'surface-action-bar-open-post-composer'"
          data-pepin-trigger="click"
          @click="handleAddPostClick"
          @long-press="handleAddPostLongPress($event)"
        >
          <template>
            <div
              :class="[
                'flex items-center justify-center',
                'rounded-full w-8 h-8',
                { 'bg-padlet-pink text-light-text-100 hover-hover:hover:bg-padlet-pink-600': !isSkinColorAvailable },
              ]"
              :style="onboardingDemoButtonStyle"
            >
              <OzIcon name="plus_thick" :size="20" />
            </div>
          </template>
        </OzBaseButton>
      </SurfaceOnboardingDemoPadletPulsatingWrapper>
      <template v-else>
        <OzBaseButton
          v-if="canIPost && isMobileLayout && !isFrozen"
          ref="addPostButtonRef"
          v-emit-long-press
          :class="[
            'grow @tablet-portrait/surface-container:grow-0',
            'flex items-center justify-center',
            // The icon size is 32x32 so we need to
            // add 4px padding to both sides to make
            // it as wide as the other 40x40 buttons.
            'px-1',
            'bg-transparent',
          ]"
          :aria-label="__('Add post')"
          :title="__('Add post')"
          :disabled="isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
          data-testid="surfaceAddPostButton"
          data-pw="surfaceAddPostButton"
          :data-pepin-id="isMap ? 'surface-action-bar-open-location-picker' : 'surface-action-bar-open-post-composer'"
          data-pepin-trigger="click"
          @click="handleAddPostClick"
          @long-press="handleAddPostLongPress($event)"
        >
          <template>
            <div
              :class="[
                'flex items-center justify-center',
                'rounded-full w-8 h-8',
                { 'bg-padlet-pink text-light-text-100 hover-hover:hover:bg-padlet-pink-600': !isSkinColorAvailable },
              ]"
              :style="onboardingDemoButtonStyle"
            >
              <OzIcon name="plus_thick" :size="20" />
            </div>
          </template>
        </OzBaseButton>
      </template>
      <OzIconButton
        v-if="isDesktopLayout && demoPadletPanelVisibility.showSurfaceActionBarButtons"
        :label="xActivityPanel ? __('Close activity panel') : __('Open activity panel')"
        :icon-name="xActivityPanel ? 'bell_filled' : 'bell'"
        :icon-size="isMobileLayout ? 24 : 16"
        :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
        :size-preset="OzIconButtonSizePreset.H40px"
        :color-scheme="xActivityPanel ? activeButtonColorScheme : buttonColorScheme"
        :dark-mode="buttonDarkMode"
        :class="[
          'grow @tablet-portrait/surface-container:grow-0',
          xActivityPanel ? activeButtonSkinBasedTailwindClasses : buttonSkinBasedTailwindClasses,
        ]"
        :data-pepin-id="
          xActivityPanel ? 'surface-action-bar-close-activity-panel' : 'surface-action-bar-open-activity-panel'
        "
        data-testid="activityPanelButton"
        data-pepin-trigger="click"
        @click="xActivityPanel ? closeActivityPanel() : showActivityPanel()"
      />
      <OzPlainButton
        v-if="isMobileLayout"
        :disabled="isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
        :aria-label="__('Open search bar')"
        :size-preset="OzPlainButtonSizePreset.H44px"
        :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
        :dark-mode="buttonDarkMode"
        :class="['grow @tablet-portrait/surface-container:grow-0', buttonSkinBasedTailwindClasses]"
        data-testid="surfaceSearchButton"
        :data-pepin-id="'surface-action-bar-open-search-bar'"
        data-pepin-trigger="click"
        @click="showSearchBar()"
      >
        <template #icon>
          <SearchIconWithIndicator
            v-if="xSearchIndicator"
            :dark-mode="buttonDarkMode"
            color-shade="text-200"
            :size="24"
          />
          <OzIcon v-else name="search" :size="24" />
        </template>
      </OzPlainButton>
      <!-- Should not show when smaller than tablet landscape because slideshow buttons are not shown at all -->
      <SurfaceOnboardingDemoPadletPulsatingWrapper
        v-if="
          !isContainerSmallerThanTabletLandscape &&
          demoPadletPanelVisibility.showModifiedSurfaceActionBarSlideshowButton
        "
        :is-smaller-than-tablet-portrait="isContainerSmallerThanTabletPortrait"
        :has-square-shape="true"
      >
        <OzIconButton
          v-if="!isMobileLayout && !isZoomCollaborationGuest && demoPadletPanelVisibility.showSurfaceActionBarButtons"
          :label="__('Open slideshow')"
          icon-name="play_outline"
          :icon-size="16"
          :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndShareStep"
          :size-preset="OzIconButtonSizePreset.H40px"
          :color-scheme="buttonColorScheme"
          :dark-mode="buttonDarkMode"
          :class="[
            'grow-0',
            buttonSkinBasedTailwindClasses,
            demoPadletPanelVisibility.showModifiedSurfaceActionBarSlideshowButton && 'text-light-text-100',
          ]"
          :style="onboardingDemoButtonStyle"
          :data-pepin-id="'surface-action-bar-open-slideshow'"
          data-testid="surfaceSlideshowButton"
          data-pepin-trigger="click"
          @click="openSlideshow"
        />
      </SurfaceOnboardingDemoPadletPulsatingWrapper>
      <template v-else>
        <OzIconButton
          v-if="!isMobileLayout && !isZoomCollaborationGuest && demoPadletPanelVisibility.showSurfaceActionBarButtons"
          :label="__('Open slideshow')"
          icon-name="play_outline"
          :icon-size="16"
          :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
          :size-preset="OzIconButtonSizePreset.H40px"
          :color-scheme="buttonColorScheme"
          :dark-mode="buttonDarkMode"
          :class="[
            'grow-0',
            buttonSkinBasedTailwindClasses,
            demoPadletPanelVisibility.showModifiedSurfaceActionBarSlideshowButton && 'text-light-text-100',
          ]"
          :data-pepin-id="'surface-action-bar-open-slideshow'"
          data-testid="surfaceSlideshowButton"
          data-pepin-trigger="click"
          @click="openSlideshow"
        />
      </template>

      <template
        v-if="
          canIAdminister &&
          !isMobileLayout &&
          !isZoomCollaborationGuest &&
          demoPadletPanelVisibility.showSurfaceActionBarButtons
        "
      >
        <SurfaceOnboardingDemoPadletPulsatingWrapper
          v-if="
            !isContainerSmallerThanTabletPortrait &&
            demoPadletPanelVisibility.showModifiedSurfaceActionBarSettingsButton
          "
          :is-smaller-than-tablet-portrait="isContainerSmallerThanTabletPortrait"
          :has-square-shape="true"
        >
          <OzIconButton
            v-if="!isFrozen"
            :label="__('Open padlet settings')"
            icon-name="gear_outline"
            :icon-size="isMobileLayout ? 24 : 16"
            :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndShareStep"
            :size-preset="OzIconButtonSizePreset.H40px"
            :color-scheme="buttonColorScheme"
            :dark-mode="buttonDarkMode"
            :class="[
              'grow @tablet-portrait/surface-container:grow-0',
              buttonSkinBasedTailwindClasses,
              demoPadletPanelVisibility.showModifiedSurfaceActionBarSettingsButton && 'text-light-text-100',
            ]"
            :style="onboardingDemoButtonStyle"
            data-testid="surfaceSettingsButton"
            data-pepin-id="surface-action-bar-show-settings-panel"
            data-pepin-trigger="click"
            @click="toggleSettingsPanel"
          />
          <OzIconButton
            v-else
            :label="__('Open freeze padlet panel')"
            icon-name="freeze"
            :icon-size="isMobileLayout ? 24 : 16"
            :size-preset="OzIconButtonSizePreset.H40px"
            :color-scheme="buttonColorScheme"
            :dark-mode="buttonDarkMode"
            :class="[
              'grow @tablet-portrait/surface-container:grow-0',
              buttonSkinBasedTailwindClasses,
              demoPadletPanelVisibility.showModifiedSurfaceActionBarSettingsButton && 'text-light-text-100',
            ]"
            @click="showSurfaceFreezePadletPanel"
          />
        </SurfaceOnboardingDemoPadletPulsatingWrapper>
        <template v-else>
          <OzIconButton
            v-if="!isFrozen"
            :label="__('Open padlet settings')"
            icon-name="gear_outline"
            :icon-size="isMobileLayout ? 24 : 16"
            :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
            :size-preset="OzIconButtonSizePreset.H40px"
            :color-scheme="buttonColorScheme"
            :dark-mode="buttonDarkMode"
            :class="[
              'grow @tablet-portrait/surface-container:grow-0',
              buttonSkinBasedTailwindClasses,
              demoPadletPanelVisibility.showModifiedSurfaceActionBarSettingsButton && 'text-light-text-100',
            ]"
            data-testid="surfaceSettingsButton"
            data-pepin-id="surface-action-bar-show-settings-panel"
            data-pepin-trigger="click"
            @click="toggleSettingsPanel"
          />
          <OzIconButton
            v-else
            :label="__('Open freeze padlet panel')"
            icon-name="freeze"
            :icon-size="isMobileLayout ? 24 : 16"
            :size-preset="OzIconButtonSizePreset.H40px"
            :color-scheme="buttonColorScheme"
            :dark-mode="buttonDarkMode"
            :class="[
              'grow @tablet-portrait/surface-container:grow-0',
              buttonSkinBasedTailwindClasses,
              demoPadletPanelVisibility.showModifiedSurfaceActionBarSettingsButton && 'text-light-text-100',
            ]"
            @click="showSurfaceFreezePadletPanel"
          />
        </template>
      </template>
      <OzIconButton
        v-if="device.zoomApp && demoPadletPanelVisibility.showSurfaceActionBarButtons"
        :label="__('Open padlet in browser')"
        icon-name="external_link_outline"
        :icon-size="isMobileLayout ? 24 : 16"
        :disabled="isSectionBreakout || isDemoPadletPanelDesktopAndShareStep"
        :size-preset="OzIconButtonSizePreset.H40px"
        :color-scheme="buttonColorScheme"
        :dark-mode="buttonDarkMode"
        :class="['grow @tablet-portrait/surface-container:grow-0', buttonSkinBasedTailwindClasses]"
        :data-pepin-id="'surface-action-bar-open-browser'"
        data-pepin-trigger="click"
        @click="openPadletInBrowser"
      />
      <OzIconButton
        v-if="!device.zoomApp && demoPadletPanelVisibility.showSurfaceActionBarButtons"
        ref="moreButton"
        :label="__('More padlet actions')"
        icon-name="dot_3_horizontal"
        :icon-size="24"
        :size-preset="OzIconButtonSizePreset.H40px"
        :color-scheme="
          isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep
            ? OzIconButtonColorScheme.Transparent
            : OzIconButtonColorScheme.SecondaryClear
        "
        :dark-mode="buttonDarkMode"
        :class="'grow @tablet-portrait/surface-container:grow-0'"
        data-testid="surfaceMoreButton"
        :disabled="isDemoPadletPanelDesktopAndPostStep || isDemoPadletPanelDesktopAndShareStep"
        data-pw="surfaceMoreButton"
        data-pepin-id="surface-action-bar-open-more-menu"
        data-pepin-trigger="click"
        @click="openMoreMenu"
      />
      <div v-if="isDesktopLayout && showSurfaceChat" class="absolute bottom-[107px] end-0 group">
        <!-- Shadows used to simulate the rounded edges when the button is clicked -->
        <svg
          v-if="xSurfaceAIChatPanel"
          class="absolute start-10 bottom-11 magic-chat-icon-button-curve"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="shadow-light-ui-100 dark:shadow-dark-ui-100"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0
          12C6.62742 12 12 6.62742 12 0V12H0Z"
            fill="none"
          />
        </svg>
        <svg
          v-if="xSurfaceAIChatPanel"
          class="absolute start-10 top-11 magic-chat-icon-button-curve"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="shadow-light-ui-100 dark:shadow-dark-ui-100"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0C6.62742 0 12 5.37258 12 12V0H0Z"
            fill="none"
          />
        </svg>
        <!-- Button skin classes should only be applied when the panel is not open -->
        <MagicChatIconButtonVue
          :class="['relative', !xSurfaceAIChatPanel && buttonSkinBasedTailwindClassesWithoutHover]"
          :is-active="xSurfaceAIChatPanel"
          :dark-mode="buttonDarkMode"
          :color-scheme="buttonColorScheme"
          @click="toggleChatPanel"
        />
      </div>
    </div>
    <SurfaceActionBarMoreMenu
      v-if="xActionBarMoreMenu && demoPadletPanelVisibility.showSurfaceActionBarButtons"
      :popover-anchor="moreMenuAnchor"
      @trigger-anchor-update="updateMoreMenuAnchor"
      @close="closeMoreMenu"
    />

    <SurfaceAddPostMenu
      v-if="xAddPostMenu && addPostButtonRef && demoPadletPanelVisibility.showSurfaceAiChat"
      :popover-anchor="addPostButtonRef.$el"
      :buttons="addPostMenuButtons"
      @close-menu="xAddPostMenu = false"
    />
  </div>
</template>

<style lang="scss" scoped>
svg.magic-chat-icon-button-curve path {
  fill: #ffffff;
  @media (prefers-color-scheme: dark) {
    fill: #111111;
  }
}
</style>
