<script setup lang="ts">
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import type { Anchor } from '@@/library/v4/components/OzBasePopover.vue'
import OzBox, { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzPopover from '@@/library/v4/components/OzPopover.vue'
import { flip, offset } from '@floating-ui/vue'

export interface Button {
  text: string
  ariaLabel: string
  icon: string
  action: () => void
}

defineProps<{
  popoverAnchor: Anchor
  buttons: Button[]
}>()

const emit = defineEmits<{
  (name: 'close-menu'): void
}>()

function handleClick(action: () => void): void {
  action()
  emit('close-menu')
}
</script>

<template>
  <OzPopover
    :anchor="popoverAnchor"
    :config="{
      placement: 'top',
      middleware: [offset(20), flip()],
    }"
    role="dialog"
    :aria-label="__('Add post menu')"
    @scrim-esc="$emit('close-menu')"
    @scrim-click="$emit('close-menu')"
  >
    <OzBox
      :color="OzBoxColors.PrimaryLight"
      :class="[
        'w-[351px]',
        'p-2',
        'backdrop-blur-6',
        'overflow-y-auto overflow-x-hidden',
        'scrollbar-thin dark:scrollbar-thin-dark',
        'flex flex-col gap-3',
        'rounded-3xl',
      ]"
      role="menu"
      :aria-label="__('Content selection')"
    >
      <div v-for="(button, index) in buttons" :key="index">
        <OzBaseButton
          :class="[
            'w-full',
            'bg-grey-100 dark:bg-grey-800',
            'group',
            'flex items-center gap-2',
            'py-2 ps-4 pe-11 rounded-2xl',
            'text-dark-text-200 dark:text-light-text-200 font-semibold',
            // Hovered
            'hhover:bg-grey-200 dark:hhover:bg-grey-600',
            // Active
            'hactive:bg-grape-500 dark:hactive:bg-canary-500 hactive:text-light-text-100 dark:hactive:text-dark-text-100',
            // Focused
            'focus-visible:rounded-2xl',
            'focus-visible:outline-none',
            'focus-visible:ring-[2.5px]',
            'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500',
          ]"
          @click="handleClick(button.action)"
          @keydown.enter.prevent="handleClick(button.action)"
        >
          <OzIcon :size="20" :name="button.icon" />
          <div class="flex-col flex-1 min-w-0 justify-center ps-0.5">
            <p class="text-heading-4 truncate">
              {{ button.text }}
            </p>
          </div>
        </OzBaseButton>
      </div>
    </OzBox>
  </OzPopover>
</template>
