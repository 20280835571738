// @file Surface Accounts Store

import initialLibrary from '@@/bits/current_library'
import { LibraryMembershipRole, LibraryType } from '@@/enums'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { NATIVE_TENANT_ID, useUserAccountsStore } from '@@/pinia/user_accounts_store'
import type { Library, LibraryId } from '@@/types'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useSurfaceAccountsStore = defineStore('surfaceAccountsStore', () => {
  const surfaceStore = useSurfaceStore()
  const userAccountsStore = useUserAccountsStore()
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()

  const currentUser = computed(() => surfaceCurrentUserStore.currentUser)
  const wallId = computed(() => surfaceStore.wallId)
  const libraryId = computed(() => surfaceStore.libraryId)
  const librariesById = computed((): Record<LibraryId, Library> => userAccountsStore.librariesById)

  const currentTenant = computed(() => userAccountsStore.currentTenant)
  const isBackpack = computed((): boolean => {
    if (currentUser.value == null) return false
    return currentTenant.value != null
      ? currentTenant.value.type === 'school'
      : currentUser.value.tenant_type === 'school'
  })
  const isBriefcase = computed((): boolean => currentTenant.value?.type === 'business')

  const isStudent = computed((): boolean => currentUser.value?.role === 'student')
  const isTeacher = computed((): boolean => currentUser.value?.role === 'teacher')
  const isAdmin = computed((): boolean => currentUser.value?.role === 'admin')
  const isOwner = computed((): boolean => currentUser.value?.role === 'owner')
  const isNativeAccount = computed((): boolean => currentUser.value?.tenant_id === NATIVE_TENANT_ID)

  const currentLibrary = computed((): Library | null => {
    if (libraryId.value == null) return null
    return librariesById.value[libraryId.value] ?? initialLibrary
  })
  const isCurrentLibrarySchool = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.School)
  const isCurrentLibraryClassroom = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.Classroom)
  const isCurrentLibraryTeam = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.Team)
  const currentLibraryId = computed((): LibraryId | null => currentLibrary.value?.id ?? null)

  // The library membership of the current user in the current library
  const currentLibraryMembership = computed(() => {
    if (currentLibrary.value == null || currentUser.value == null) return null
    if (currentLibrary.value.membership == null) return null
    return currentLibrary.value?.membership.find((m) => m.user_id === currentUser.value?.id) || null
  })
  const isLibraryOwner = computed(() => currentLibraryMembership.value?.role === LibraryMembershipRole.Owner)
  const isLibraryAdmin = computed(() => currentLibraryMembership.value?.role === LibraryMembershipRole.Admin)
  const isLibraryTeacher = computed(() => currentLibraryMembership.value?.role === LibraryMembershipRole.Teacher)

  async function initialize(): Promise<void> {
    if (surfaceCurrentUserStore.currentUser == null) return
    await userAccountsStore.initialize({ user: surfaceCurrentUserStore.currentUser })
  }

  return {
    // State

    // Getters
    currentUser,
    currentTenant,
    wallId,
    libraryId,
    librariesById,
    currentLibrary,
    isCurrentLibrarySchool,
    isCurrentLibraryClassroom,
    isCurrentLibraryTeam,
    currentLibraryId,
    currentLibraryMembership,
    isLibraryOwner,
    isLibraryAdmin,
    isLibraryTeacher,
    isStudent,
    isTeacher,
    isAdmin,
    isOwner,
    isNativeAccount,
    isBackpack,
    isBriefcase,

    // Actions
    initialize,
  }
})
